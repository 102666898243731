import React from 'react';
import Icon from '../Icon';
import './payout-tally.scss';

const PayoutTally = () => {
  return (
    <div className="payout-tally">
      <section className="multiple-recharge-methods pt-[48px] md:py-[96px] payout-tally-title-meta-data">
        <div className="container">
          <div className="row justify-flex-start mobile-wrap">
            <div className="column">
              <div className="content-block relative">
                <h2 className="text-white text-4md md:text-shl body-text">
                  Introducing all-new Payouts Tally plugin!
                </h2>
                <p className="mt-[12px] md:mt-[20px] text-white text-2sm md:text-base font-normal">
                  Pay all accounts payables -vendors, salary, bills etc
                  instantly and automate reconciliations within Tally ERP.
                </p>
                <div className="anim-faq payout-tally-title-checkpoints">
                  <ul className="check-list-round md:mt-[28px] md:mb-[32px] !max-w-[515px]">
                    <li>
                      <span className="mt-1">
                        <Icon
                          name="tick"
                          width="9.21px"
                          height="6.29px"
                          fill="#fff"
                        />
                      </span>
                      <p className="body-text text-cf-cold-purple md:text-[15px] text-[14px]">
                        Setup custom approvals for direct-from-ERP transfers
                      </p>
                    </li>
                    <li>
                      <span className="mt-1">
                        <Icon
                          name="tick"
                          width="9.21px"
                          height="6.29px"
                          fill="#fff"
                        />
                      </span>
                      <p className="body-text text-cf-cold-purple md:text-[15px] text-[14px]">
                        Tally ledgers get auto-updated with payment statuses, in
                        real time
                      </p>
                    </li>
                    <li>
                      <span className="mt-1">
                        <Icon
                          name="tick"
                          width="9.21px"
                          height="6.29px"
                          fill="#fff"
                        />
                      </span>
                      <p className="body-text text-cf-cold-purple md:text-[15px] text-[14px]">
                        Do instant payment to any a/c payable or upload 1000+
                        beneficiaries in one go and do instant money transfer
                      </p>
                    </li>
                  </ul>
                  <a
                    href="https://merchant.cashfree.com/merchants/signup?source-action=Payout%20Page&action=Sign%20Up&button-id=Tally_Plugin"
                    rel="noopener noreferrer"
                    className="button button-green">
                    Add Payouts plugin to your Tally ERP Now!{' '}
                    <span className="chevron" />
                  </a>
                </div>
              </div>
            </div>
            <div className="column">
              <div className="accordion-anim-images payout-accordions flex justify-end">
                <img
                  height={287}
                  width={438.5}
                  src="/img/payout/tally.svg"
                  alt="tally-payout"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default PayoutTally;
