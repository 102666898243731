import React from 'react';
import CheckList from '../components/CheckList/CheckList';
import TabPanel from '../components/Tabs/TabPanel';

const headingClass = 'mb-2 md:mb-5';
const contentBodyMarginClass = 'mb-1 md:mb-8 max-w-[500px]';
const imageClassName = 'w-full md:pr-[18px]';

const contentClassName = 'md:pl-[19px] mt-0  md:pt-[30px]';
const mobileVisibleHeading = false;
// const alignStart = true;

function createAccountAnchor(text, href) {
  return (
    <div className="mb-3">
      <a
        href={href}
        rel="noopener noreferrer"
        className="button button-green w-full md:hidden mt-6">
        {text} <span className="chevron" />
      </a>
      <a
        href={href}
        className="link text-cf-green hover:text-cf-green hidden md:flex">
        {text}
        <svg
          className="ml-2.5"
          width="6"
          height="11"
          viewBox="0 0 6 11"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M1 1L5 5.5L1 10"
            stroke="#05C16E"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </a>
    </div>
  );
}

const reImaginedCheckListData = [
  [
    {
      id: 0,
      text: 'No need for manual recharges',
    },
    {
      id: 1,
      text: 'Make instant transfers',
    },
    {
      id: 2,
      text: 'Increase success rate with multi-bank accounts',
    },
  ],
  [
    {
      id: 0,
      text: 'Assisted escrow account opening',
    },
    {
      id: 1,
      text:
        'Move money directly from between accounts without any manual intervention',
    },
    {
      id: 2,
      text: 'Track transfer status and reasons for failure in real-time',
    },
    {
      id: 3,
      text: 'Easy reconciliation',
    },
  ],
  [
    {
      id: 0,
      text: 'Bring Your Own Corporate Credit Card',
    },
    {
      id: 1,
      text: 'Add multiple corporate cards',
    },
    {
      id: 2,
      text: 'Get access to funds immediately',
    },
  ],
  [
    {
      id: 0,
      text: 'Add your Paytm account instantly',
    },
    {
      id: 1,
      text: 'Get instant transfer confirmation',
    },
    {
      id: 2,
      text: 'No need for separate integration',
    },
  ],
  [
    {
      id: 0,
      text:
        'Get industry-leading success rate using smart routing via multiple gateways',
    },
    {
      id: 1,
      text:
        'Recharge instantly and choose to pay to bank account, UPI ID, AmazonPay or any tokenized cards',
    },
    {
      id: 2,
      text: 'Get the wallet balance updated in real-time',
    },
  ],
  [
    {
      id: 0,
      text: 'Minimal documentation',
    },
    {
      id: 1,
      text: 'Get a pre-approved credit limit from multiple lending partners',
    },
    {
      id: 2,
      text: 'Get on-demand disbursals',
    },
  ],
];

export const reImaginedCardData = [
  {
    id: 0,
    title: 'Multiple Current Accounts',
    src: '/img/payout/multiple-account.svg',
    content: (
      <div className="flex flex-col md:h-full">
        <div className="md:flex-grow">
          <CheckList data={reImaginedCheckListData[0]} />
          <div className="flex space-x-[16px] md:space-x-[4px] xl:space-x-[9px] h-14 md:h-14 xl:h-16">
            <img src="/img/payout/icici.svg" alt="icici" height={64} />
            <img src="/img/payout/kotak.svg" alt="kotak" height={64} />
            <img src="/img/payout/indusind.svg" alt="indusind" height={64} />
            <div className="relative w-14 md:w-14 xl:w-16">
              <img src="/img/payout/rbl-comingsoon.svg" alt="rbl" />
              <img
                src="/img/payout/coming-soon.svg"
                alt="coming soon"
                height={34}
                className="absolute top-[-10px] right-[-40px]"
              />
            </div>
          </div>
        </div>
        {createAccountAnchor(
          'Create Account',
          'https://merchant.cashfree.com/merchants/signup?source-action=Payout%20Page&action=Sign%20Up&button-id=Create_Account_fundsource',
        )}
      </div>
    ),
  },
  {
    id: 1,
    title: 'Escrow Accounts',
    src: '/img/payout/escrow-account.svg',
    content: (
      <div className="flex flex-col h-full">
        <CheckList data={reImaginedCheckListData[1]} className="flex-grow" />
        {createAccountAnchor(
          'Create Account',
          'https://merchant.cashfree.com/merchants/signup?source-action=Payout%20Page&action=Sign%20Up&button-id=Create_Account_fundsource',
        )}
      </div>
    ),
  },
  {
    id: 2,
    title: 'Corporate Credit Cards',
    src: '/img/payout/coporate-cc.svg',
    content: (
      <div className="flex flex-col h-full">
        <div className="md:flex-grow">
          <CheckList data={reImaginedCheckListData[2]} />
          <div className="flex space-x-[16px] md:space-x-[4px] xl:space-x-[9px] h-14 md:h-14 xl:h-16">
            <img src="/img/payout/visa.svg" alt="visa" height={64} />
            <img
              src="/img/payout/mastercard.svg"
              alt="mastercard"
              height={64}
            />
          </div>
        </div>
        {createAccountAnchor(
          'Create Account',
          'https://merchant.cashfree.com/merchants/signup?source-action=Payout%20Page&action=Sign%20Up&button-id=Create_Account_fundsource',
        )}
      </div>
    ),
  },
  {
    id: 3,
    title: 'Paytm Wallets',
    src: '/img/payout/paytm-wallet.svg',
    content: (
      <div className="flex flex-col h-full">
        <CheckList data={reImaginedCheckListData[3]} className="flex-grow" />
        {createAccountAnchor(
          'Create Account',
          'https://merchant.cashfree.com/merchants/signup?source-action=Payout%20Page&action=Sign%20Up&button-id=Create_Account_fundsource',
        )}
      </div>
    ),
  },
  {
    id: 4,
    title: 'Cashfree Wallet',
    src: '/img/payout/Cashfree-wallet.svg',
    content: (
      <div className="flex flex-col h-full">
        <CheckList data={reImaginedCheckListData[4]} className="flex-grow" />
        {createAccountAnchor(
          'Create Account',
          'https://merchant.cashfree.com/merchants/signup?source-action=Payout%20Page&action=Sign%20Up&button-id=Create_Account_fundsource',
        )}
      </div>
    ),
  },
  {
    id: 5,
    title: 'External Credit Lines',
    src: '/img/payout/ExternalCreditLine.svg',
    content: (
      <div className="flex flex-col h-full">
        <CheckList data={reImaginedCheckListData[5]} className="flex-grow" />
        {createAccountAnchor(
          'Create Account',
          'https://merchant.cashfree.com/merchants/signup?source-action=Payout%20Page&action=Sign%20Up&button-id=Create_Account_fundsource',
        )}
      </div>
    ),
  },
];

export const mobileTabData = [
  {
    key: 0,
    heading: (
      <div className="flex items-center">
        <img
          className="w-[24px] h-[24px] mr-3"
          src={reImaginedCardData[0].src}
          alt=""
        />
        <p>{reImaginedCardData[0].title}</p>
      </div>
    ),
    content: reImaginedCardData[0].content,
  },
  {
    key: 1,
    heading: (
      <div className="flex items-center">
        <img
          className="w-[24px] h-[24px] mr-3"
          src={reImaginedCardData[1].src}
          alt=""
        />
        <p>{reImaginedCardData[1].title}</p>
      </div>
    ),
    content: reImaginedCardData[1].content,
  },
  {
    key: 2,
    heading: (
      <div className="flex items-center">
        <img
          className="w-[24px] h-[24px] mr-3"
          src={reImaginedCardData[2].src}
          alt=""
        />
        <p>{reImaginedCardData[2].title}</p>
      </div>
    ),
    content: reImaginedCardData[2].content,
  },
  {
    key: 3,
    heading: (
      <div className="flex items-center">
        <img
          className="w-[24px] h-[24px] mr-3"
          src={reImaginedCardData[3].src}
          alt=""
        />
        <p>{reImaginedCardData[3].title}</p>
      </div>
    ),
    content: reImaginedCardData[3].content,
  },
  {
    key: 4,
    heading: (
      <div className="flex items-center">
        <img
          className="w-[24px] h-[24px] mr-3"
          src={reImaginedCardData[4].src}
          alt=""
        />
        <div className="flex items-end mt-4 md:mb-5">
          <p className="font-semibold leading-6">
            {reImaginedCardData[4].title}
          </p>
          {/* <p className="text-vtiny text-cf-primary font-semibold ml-1 mb-1">
            [Powered by Cashfree]
          </p> */}
        </div>
      </div>
    ),
    content: reImaginedCardData[4].content,
  },
  {
    key: 5,
    heading: (
      <div className="flex items-center">
        <img
          className="w-[24px] h-[24px] mr-3"
          src={reImaginedCardData[5].src}
          alt=""
        />
        <p>{reImaginedCardData[5].title}</p>
      </div>
    ),
    content: reImaginedCardData[5].content,
  },
];

export const checkoutOptionsData = [
  {
    key: 0,
    heading: 'Quick Transfer',

    content: (
      <div className="md:mt-[60px]">
        <TabPanel
          alignStart={false}
          headingClass={headingClass}
          img={{
            src: '/img/payout/quick-transfer.svg',
            placeholder: '/img/upi-payment/quick transfer.svg',
            width: '610',
            height: '431',
          }}
          heading="Quick Transfer"
          headingType="div"
          mobileVisibleHeading={mobileVisibleHeading}
          imageClassName={imageClassName}
          contentClassName={contentClassName}
          body={
            <>
              <div className={contentBodyMarginClass}>
                <h2 className="font-body inline">Do instant payout</h2> to
                users, customers, and vendors using Quick Transfer. Get
                flexibility on the fund source you want to disburse the money
                from and the transfer mode.
              </div>
            </>
          }
          // footer={
          //   <a
          //     className="button button-green mt-8 md:mt-0 mr-4 wide w-full md:w-auto"
          //     href="https://merchant.cashfree.com/merchants/signup?source-action=UPI%20PG&action=Sign%20Up&button-id=StartNow_UPIIntentFlow">
          //     Create Account <span className="chevron" />
          //   </a>
          // }
        />
      </div>
    ),
  },
  {
    key: 1,
    heading: 'Bulk Payouts',

    content: (
      <div className="md:mt-[60px]">
        <TabPanel
          alignStart={false}
          headingClass={headingClass}
          img={{
            src: '/img/payout/bulk-payouts.png',
            placeholder: '/img/payout/bulkpayouts.svg',
            width: '1830',
            height: '1293',
          }}
          heading="Bulk Payouts"
          headingType="div"
          mobileVisibleHeading={mobileVisibleHeading}
          imageClassName={imageClassName}
          contentClassName={contentClassName}
          body={
            <>
              <div className={contentBodyMarginClass}>
                Add beneficiaries and do up to 10,000 bulk payouts in one go
                with simple excel file upload. Track transfer status in real
                time and know exact reason for failure.
              </div>
            </>
          }
          // footer={
          //   <a
          //     className="button button-green mt-8 md:mt-0 mr-4 wide w-full md:w-auto"
          //     href="https://merchant.cashfree.com/merchants/signup?source-action=UPI%20PG&action=Sign%20Up&button-id=StartNow_UPIDynamicQRcode">
          //     Create Account <span className="chevron" />
          //   </a>
          // }
        />
      </div>
    ),
  },
  {
    key: 2,
    heading: 'Payouts API',

    content: (
      <div className="md:mt-[60px]">
        <TabPanel
          alignStart={false}
          headingClass={headingClass}
          img={{
            src: '/img/payout/payouts-api.svg',
            placeholder: '/img/upi-payment/payoutsAPI.svg',
            width: '610',
            height: '431',
          }}
          heading="Payouts API"
          headingType="div"
          mobileVisibleHeading={mobileVisibleHeading}
          imageClassName={imageClassName}
          contentClassName={contentClassName}
          body={
            <>
              <div className={contentBodyMarginClass}>
                Integrate Payouts with your native solution and expedite upto
                10,000 payouts via APIs. Generate custom reports and gain
                insights - transfers, reversed transfers, statements and more.
              </div>
            </>
          }
          // footer={
          //   <a
          //     className="button button-green mt-8 md:mt-0 mr-4 wide w-full md:w-auto"
          //     href="https://merchant.cashfree.com/merchants/signup?source-action=UPI%20PG&action=Sign%20Up&button-id=StartNow_WhatsappUPILinks">
          //     Create Account <span className="chevron" />
          //   </a>
          // }
        />
      </div>
    ),
  },
];

export const exploreOtherProducts = [
  {
    id: 0,
    title: 'Fund Sources',
    body: 'Add multiple fund sources and make payouts directly.',
    url: 'https://www.cashfree.com/payouts/icici-current-account/',
    iconname:
      'https://cashfreelogo.cashfree.com/website/landings/payouts/payouts-direct.svg',
  },
  {
    id: 1,
    title: 'Global Payouts',
    body:
      'Add funds in USD, EUR and other currencies and send money to your sellers, service providers or freelancers in India directly to their local bank account in India. ',
    url: 'https://www.cashfree.com/send-money-to-india/global-payouts/',
    iconname:
      'https://cashfreelogo.cashfree.com/website/landings/payouts/global-payouts.svg',
  },
  {
    id: 2,
    title: 'Bank Account Verification',
    body:
      'Use complete verification suite for e-KYC and verify beneficiary bank account or UPI ID before sending money. IFSC verification API is now available [Free].',
    url: 'https://www.cashfree.com/bank-account-verification/',
    iconname: '/img/payout/bank-account-verification.svg',
  },
  {
    id: 3,
    title: 'Payment Gateway',
    body: 'Accept domestic and international payments for your website or app',
    url: 'https://www.cashfree.com/payment-gateway-india/',
    iconname:
      'https://cashfreelogo.cashfree.com/website/newNavS3/payment-gateway.svg',
  },
];

export const gatewayCards = [
  {
    id: 0,
    title: 'Bank Account',
    text:
      'Send money to your customer’s bank account via IMPS, NEFT, or RTGS instantly. No cool off period for beneficiary addition!',
    src: '/img/payout/banking.svg',
  },
  {
    id: 1,
    title: 'UPI or Wallets',
    text:
      'Initiate disbursals to customers UPI ID directly or add funds to their Paytm and Amazon Pay wallets.',
    src: '/img/payout/UPI.svg',
  },
  {
    id: 2,
    title: 'Tokenised Cards',
    text: 'Transfer money to any tokenised credit and debit cards instantly.',
    src: '/img/payout/tokenisation-cards.svg',
  },
  {
    id: 3,
    title: 'Prepaid Accounts',
    text:
      'Get your own branded prepaid cards with higher success rate compared to traditional payment modes. Instantly add wallet balances to the cards in real-time.',
    src: '/img/payout/prepaid cards.svg',
  },
];

export const faqData = [
  {
    id: 0,
    q: 'What is Cashfree Payouts?',
    a: (
      <p>
        Cashfree Payouts is a payment disbursal platform used by businesses to
        do bulk payouts. When you create a Payouts account, Cashfree creates a
        virtual wallet for you. You can add funds from your bank accounts to the
        wallet and use the funds to do payouts. You can use Payouts dashboard to
        do single or bulk payouts via excel upload or integrate Payouts APIs
        with your product or ERP to automate bulk payouts.
      </p>
    ),
  },
  {
    id: 1,
    q: 'What can I use Cashfree Payouts for?',
    a: (
      <>
        <p>
          You can use Cashfree Payouts for a wide range of business use cases.
          Here is how some of the leading businesses in India are using Payouts:
        </p>
        <ul className="list-disc">
          <li>
            Hyperlocal services like Grab and Dunzo use it for on-demand wage
            payouts.
          </li>
          <li>
            E-commerce platforms like Nykaa and Xiaomi use Payouts APIs for
            instant refunds processing.
          </li>
          <li>
            Loan providers like IndiaLends and Cashbean use it for instant loan
            disbursals.
          </li>
          <li>
            Gaming websites like Dream11 and RummyCircle use Payouts for
            real-time player&apos;s winning cashouts.
          </li>
          <li>
            Marketplace platforms like Milaap and Tata Sky have integrated
            Payouts with their products for automated vendor payouts.
          </li>
        </ul>
      </>
    ),
  },
  {
    id: 2,
    q: 'How is Payouts different from using banks for bulk payouts?',
    a: (
      <>
        <p>
          Bulk payouts via bank accounts happen by manually adding every
          beneficiary before transferring or by uploading a spfsheet on the
          banking portal. Cashfree automates bulk payouts fully via APIs and
          offers a much simpler alternative.
        </p>
        <p>
          Unlike banks, where a single error in a payout file can block all the
          bulk payouts, using Cashfree, even if there are invalid records in a
          file, valid transfers go through. Reconciliation for failed and
          invalid transfers is automated so you always know which transfer
          failed out of hundreds of payouts, and why. At Cashfree, new
          beneficiary addition and activation is instant.
        </p>
        <p>
          Cashfree also allows you to send money to any Paytm Wallet, Amazon Pay
          Wallet, UPI ID or native wallets in addition to bank accounts, even on
          holidays. Lastly, all of this can be automated and built into your
          product or internal systems using our powerful Payouts APIs.
        </p>
      </>
    ),
  },
  {
    id: 3,
    q: 'How can I get started? What documents are needed?',
    a: (
      <>
        <p>
          For faster Payouts account activation,{' '}
          <a
            className="external-link"
            href="https://merchant.cashfree.com/merchants/signup"
            target="_blank"
            rel="noreferrer">
            signup
          </a>{' '}
          here. Once logged in, click on activate Payouts and add your business
          details and upload copies of your business documents. Follow the steps
          to{' '}
          <a
            className="external-link"
            href="https://dev.cashfree.com/get-started#account-activation"
            target="_blank"
            rel="noopener noreferrer">
            complete your account activation.
          </a>
        </p>
        <p>
          In case of any queries or if you are looking for a customized
          solution, get in touch with our sales team{' '}
          <a
            className="external-link"
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.cashfree.com/contact-sales/?source-action=Payout%20Page">
            here
          </a>
          . Our team will connect with you in 24 working hours. Meanwhile, you
          can also explore the product in test environment and use test API
          credentials to try out the integration before going live. View{' '}
          <a
            className="external-link"
            href="https://dev.cashfree.com/payouts/"
            target="_blank"
            rel="noopener noreferrer">
            developer documentation here.
          </a>
        </p>
      </>
    ),
  },
  {
    id: 4,
    q: 'Do I need to be a registered business?',
    a: (
      <p>
        Yes, Payouts can be used only by registered businesses. If you are not
        yet registered as a business but plan to start soon, you can signup and
        explore the product in test environment. We can also set up an account
        with limits on payout volume after understanding your business and
        future needs.
      </p>
    ),
  },
  {
    id: 5,
    q: 'Do you support payouts to all banks and bank accounts?',
    a: (
      <p>
        Yes, you can make payouts to any active savings or current bank account
        in India. However, NRE and NRO bank accounts are not supported.
      </p>
    ),
  },
  {
    id: 6,
    q: 'Do you support payouts to and from other countries?',
    a: (
      <>
        <p>
          Yes, it is possible to send bulk payouts to beneficiaries in India
          from another country using{' '}
          <a
            className="external-link"
            href="https://www.cashfree.com/send-money-to-india/global-payouts/"
            target="_blank"
            rel="noopener noreferrer">
            Global Payouts.
          </a>
          The funds can be added by the businesses in USD, EUR and other freely
          convertible currencies. The purpose of the payment must be known while
          setting up the account.
        </p>
        <p>
          An Indian business can also send a payout to other countries. However,
          there are restrictions around the volume of payments and the purpose
          of payments. Get in touch with us to learn&nbsp;more.
        </p>
      </>
    ),
  },
  {
    id: 7,
    q: 'Can I transfer funds on Sundays and bank holidays?',
    a: (
      <p>
        Yes, it is possible to make payouts even on Sundays and bank holidays.
      </p>
    ),
  },
  {
    id: 8,
    q: 'How long does it take to transfer funds?',
    a: (
      <p>
        Transfers to bank accounts happen instantly. Transfers for amounts in
        excess of ₹2,00,000 are restricted by banking hours and can take longer.
        Payouts to other modes like Paytm, AmazonPay wallet, cards, UPI ID
        happen instantly.
      </p>
    ),
  },
  {
    id: 9,
    q: 'What happens in case the account details are incorrect?',
    a: (
      <>
        <div>
          Transfers to incorrect account details fail instantly. For certain
          banks, it may take up to 24 hours to receive a confirmation of
          failure. To ensure that account details are correct, you can activate{' '}
          <a
            className="external-link"
            href="https://www.cashfree.com/bank-account-verification/"
            target="_blank"
            rel="noopener noreferrer">
            Cashfree Bank Account Verification
          </a>{' '}
          and verify the beneficiary account validity and beneficiary name at
          the bank.
        </div>
      </>
    ),
  },
  {
    id: 10,
    q: 'Is it simpler to send bulk payouts file uploads or via APIs?',
    a: (
      <p>
        Using bulk file uploads or manual transfers requires no technical
        expertise and is the quickest way to get started. However, if you want
        to automate the payouts from your product or ERP, APIs should be used.
        Integrating the API can take a day or two depending on the complexity of
        integration. Many businesses start by using the bulk file upload or
        manual transfer option, and gradually automate operations by integrating
        the API. View{' '}
        <a
          className="external-link"
          href="https://dev.cashfree.com/payouts-api/"
          target="_blank"
          rel="noopener noreferrer">
          developer documentation&nbsp;here.
        </a>
      </p>
    ),
  },
];
