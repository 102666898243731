import React from 'react';
import './paymentReimaginedStyle.scss';
import Tabs from '../Tabs/Tabs';
import { mobileTabData, reImaginedCardData } from '../../content/payout';

const PayoutReimagined = () => {
  //   const [accordionAnimImage, setAccordionAnimImage] = useState(0);
  //   const getAccordionAnimImage = useCallback((id) => {
  //     setAccordionAnimImage(id);
  //   }, []);
  return (
    <div className=" bg-cf-light-grey py-[48px] md:py-[96px]">
      <div className="container">
        <div className="text-shs md:text-shl font-semibold mb-[17px] md:mb-5 font-heading">
          Payouts, reimagined.
        </div>
        <div className="text-2sm md:text-[15px] leading-6 max-w-[720px] mb-8 md:mb-[28px]">
          <h2 className="font-body inline">Add multiple Payout Fund Sources</h2>{' '}
          and pay your vendors, partners, employees, or customers into the
          destination payment mode of their choice.
        </div>
        <div className="hidden md:flex flex-wrap md:-mx-3.5">
          {reImaginedCardData.map(({ title, src, content, id }) => (
            <div className="mt-8 w-1/2 lg:w-1/3 px-3.5" key={id}>
              <div className="px-[16px] py-[24px] bg-white h-full min-h-[390px] lg:min-h-[410px] rounded flex flex-col">
                <img src={src} alt={title} height={40} width={40} />
                <div className="flex items-end mt-4 md:mb-5">
                  <p className="font-semibold leading-6 text-[16px]">{title}</p>
                  {id === 4 && (
                    <p className="text-vtiny text-cf-primary font-semibold ml-1 mb-1">
                      [Powered by Cashfree]
                    </p>
                  )}
                </div>
                <div className="flex-grow">{content}</div>
              </div>
            </div>
          ))}
        </div>
        <div className="block md:hidden bav-tab-panel">
          <Tabs
            data={mobileTabData}
            dropDown
            dropDownFontSize="text-[16px]"
            timerAutoPlay
          />
        </div>
      </div>
    </div>
  );
};

export default PayoutReimagined;
