import React from 'react';
import './highlyreliable-style.scss';
// import HighlyReliableCard from './highlyReliableCard';
// import { HighlyReliableData } from './payoutMetaData';

const HighlyReliable = () => {
  const reliableData = [
    {
      id: 0,
      title: 'Industry-best success rate at 99.98%',
      description:
        'Payouts employs multiple functionalities to ensure all your transfers are successful round the clock.',
      icon: 'success',
    },
    {
      id: 1,
      title: 'Trusted relationship',
      description:
        'Support for all bankside relationships - failed transfers, reversal, and reconciliation.',
      icon: 'trusted',
    },
    {
      id: 2,
      title: 'Near 100% reconciliation in 5 minutes ',
      description:
        'Transfers, failures, reversals and reconciliation - automate reconciliation across multiple transfer modes, destination banks and reporting formats.',
      icon: 'reconcilation',
    },
    {
      id: 3,
      title: 'Dynamic routing of transactions',
      description:
        'Smart routing and queuing among multiple banks for guaranteed higher uptime and success rate. Intelligent re-routing of failed transfers to improve help make successful payouts.',
      icon: 'dynamicrouting',
    },
    {
      id: 4,
      title: 'Powerful risk engine',
      description:
        'Daily transaction limits, fraud detection, along with multiple security checks in place at API and dashboard levels to help reduce risky transactions.',
      icon: 'riskengine',
    },
  ];

  return (
    <div className="highly-reliable py-[48px] md:py-[96px]">
      <div className="container">
        <h2 className="text-4md md:text-shl md:mb-[22px]">
          Highly reliable at scale!
        </h2>
        <div className="flex flex-wrap md:-mx-3.5">
          {reliableData?.map(({ icon, title, description, id }) => (
            <div className="w-full mt-4 md:mt-10 md:px-3.5 md:w-1/3" key={id}>
              <div className="px-[16px] py-[24px] bg-white h-full min-h-[232px] rounded">
                <img
                  src={`/img/payout/${icon}.svg`}
                  className="w-[40px] h-[40px] md:w-[40px] md:h-[40px]"
                  width="40"
                  height="40"
                  alt=""
                />
                <div className="font-body font-semibold mt-[16px] mb-[8px] md:text-[16px]">
                  {title}
                </div>
                <div className="text-2sm">{description}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HighlyReliable;
