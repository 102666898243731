/* eslint-disable react/no-unescaped-entities */
import React, { useState, useCallback, useEffect, useRef } from 'react';
// import lottie from 'lottie-web-light';

import Helmet from 'react-helmet';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Icon from '../components/Icon';
import GetStarted from '../components/GetStarted';
import FAQ from '../components/FAQ';
// import Scrollspy from "../components/Scrollspy";
// import PayoutReimagined from '../components/Payout/payoutReimagined';
// import DestinationPayment from '../components/Payout/destinationPayment';
import HighlyReliable from '../components/Payout/highlyReliable';
// import MakeInstant from '../components/Payout/makeInstant';
import PayoutTally from '../components/Payout/payoutTally';
import BusinessSlider from '../components/Sliders/BusinessSlider/BusinessSlider';
// import EnterprisePricing from '../components/common/EnterprisePricing/EnterprisePricing';
// import { businessCards } from '../content/payment-links';

import '../styles/components/content-image-block.scss';
import '../styles/components/usecases.scss';
import '../styles/components/gateway.scss';
import '../styles/components/pricing-cards.scss';
import '../styles/components/section-features.scss';
import '../styles/components/section-developers.scss';
import '../styles/components/terminal-asset.scss';
import '../styles/components/other-products.scss';
import '../styles/components/feature-heading.scss';
import '../styles/payouts.scss';
import PayoutReimagined from '../components/Payout/payoutReimagined';
import Tabs from '../components/Tabs/Tabs';
import { tabChange } from '../components/Tabs/utlis';
import {
  checkoutOptionsData,
  exploreOtherProducts,
  faqData,
} from '../content/payout';
import TransparentVideo from '../components/TransparentVideo/TransparentVideo';
import LogoMarquee from '../components/LogoMarquee/LogoMarquee';
// import { animbuttonText } from "../utils/contentProvider";
// import ContactSales from '../components/ContactSales';
// import useLottie from '../hooks/useLottie';

// const useCases = [
//   {
//     id: 0,
//     logoBg: '#000',
//     usecaseFeature: 'Credit bank account',
//     usecaseDescription: (
//       <>
//         <p className="md:w-[310px] lg:w-[314px]">
//           Cred the credit card bill payment platform uses Payouts to{' '}
//           <span className="bold">
//             send money to the bank account linked to card
//           </span>{' '}
//           while making credit card repayment easier for customers.
//         </p>
//       </>
//     ),
//   },
//   {
//     id: 1,
//     logoBg: '#FF0078',
//     usecaseFeature: 'Instant ecommerce refunds',
//     usecaseDescription: (
//       <>
//         <p className="md:w-[310px] lg:w-[314px]">
//           Nykaa is winning customer trust and loyalty more than ever before by
//           doing <span className="bold">instant refunds</span> using Payouts.
//         </p>
//       </>
//     ),
//   },
//   {
//     id: 2,
//     logoBg: '#364DF7',
//     usecaseFeature: 'On-demand payouts',
//     usecaseDescription: (
//       <>
//         <p className="md:w-[310px] lg:w-[314px]">
//           Porter the intra-city logistics platform uses Payouts to do{' '}
//           <span className="bold">On-demand payouts</span> to its driver partners
//           at the end of every trip.
//         </p>
//       </>
//     ),
//   },
// ];
const businessCards = [
  {
    id: 0,
    image: {
      src: '/img/payout/cred.png',
      width: '492',
      height: '174',
      alt: 'orchids',
    },
    background: '#000',
    text: (
      <p>
        Cred the credit card bill payment platform uses Payouts to{' '}
        <span className="bold">
          send money to the bank account linked to card
        </span>{' '}
        while making credit card repayment easier for customers.
      </p>
    ),
    focus: [
      {
        id: 0,
        text: 'Credit bank account',
      },
    ],
  },
  {
    id: 1,
    image: {
      src: '/img/payout/nykaa.svg',
      width: '103',
      height: '106',
      alt: 'apml',
    },
    background: '#FF0078',
    text: (
      <p>
        Nykaa is winning customer trust and loyalty more than ever before by
        doing <span className="bold">Instant Refunds</span> using Payouts.
      </p>
    ),
    focus: [
      {
        id: 0,
        text: 'Instant ecommerce refunds',
      },
    ],
  },
  {
    id: 2,
    image: {
      src: '/img/payout/porter.svg',
      width: '160',
      height: '57',
      alt: 'tata-cliq',
    },
    background: '#364DF7',
    isVideo: true,
    text: (
      <p>
        Porter the intra-city logistics platform uses Payouts to do{' '}
        <span className="bold">On-demand Payouts</span> to its driver partners
        at the end of every trip.
      </p>
    ),
    focus: [
      {
        id: 0,
        text: 'On-demand payouts',
      },
    ],
  },
];
const selector = 'video-tabs';

const PayoutsPage = (props) => {
  const ref1 = useRef();
  const ref2 = useRef();

  const [gatwayActive, setGatewayActive] = useState(0);
  const toggleGatewayAccordion = useCallback(
    (id) => () => setGatewayActive((state) => (state === id ? -1 : id)),
    [],
  );

  // const [animPayoutHero, setAnimPayoutHero] = useState(0);

  // const updateAnimPayoutHero = useCallback(() => {
  //   setAnimPayoutHero((state) => (state === 3 ? 0 : state + 1));
  // }, []);

  // useEffect(() => {
  //   const interval = setInterval(() => updateAnimPayoutHero(), 3000);

  //   return () => clearInterval(interval);
  // }, [updateAnimPayoutHero]);

  // const [animPayoutFloatingBox, setAnimPayoutFloatingBox] = useState(0);

  // const updateAnimPayoutFloatingBox = useCallback(() => {
  //   setAnimPayoutFloatingBox((state) => (state === 1 ? 0 : state + 1));
  // }, []);

  // useEffect(() => {
  //   const interval = setInterval(() => updateAnimPayoutFloatingBox(), 5000);

  //   return () => clearInterval(interval);
  // }, [updateAnimPayoutFloatingBox]);

  // animation for cashgram

  const [cashgramAnim, setCashgramAnim] = useState(0);

  const updateCashgramAnim = useCallback(() => {
    setCashgramAnim((state) => (state === 23 ? 0 : state + 1));
  }, []);

  useEffect(() => {
    const interval = setInterval(() => updateCashgramAnim(), 1000);

    return () => clearInterval(interval);
  }, [updateCashgramAnim]);

  // const animationData = useLottie(
  //   'https://cashfreelogo.cashfree.com/website/lottie/payout-feature.json',
  // );
  // const featureAnimref = useRef();
  // useEffect(() => {
  //   if (animationData) {
  //     lottie.loadAnimation({
  //       container: featureAnimref.current,
  //       renderer: 'svg',
  //       loop: true,
  //       autoplay: true,
  //       animationData,
  //     });
  //   }
  // }, [animationData]);

  // const [sliderActive, setSliderActive] = useState(0);
  // const sliderClickHandler = useCallback((e) => {
  //   const { type } = e.target.closest('button').dataset;
  //   const sliderWrapper = e.target
  //     .closest('.usecase-main')
  //     .querySelector('.usecase-scroll-area');
  //   const totalLength =
  //     sliderWrapper.children.length -
  //     Math.round(
  //       sliderWrapper.offsetWidth / sliderWrapper.children[0].offsetWidth,
  //     );

  //   setSliderActive((state) => {
  //     if (type === 'next') return state === totalLength ? 0 : state + 1;
  //     return state <= 0 ? totalLength : state - 1;
  //   });
  // }, []);

  const [OpenVideoModal, setOpenVideoModal] = useState(false);

  const OpenVideoModalToggle = useCallback(() => {
    setOpenVideoModal((state) => !state);
    document.body.classList.toggle('overflow');
  }, []);

  // const leftSectionList = [
  //   {
  //     id: 0,
  //     name: 'Current Accounts',
  //   },
  //   {
  //     id: 1,
  //     name: 'Escrow Accounts',
  //   },
  //   {
  //     id: 2,
  //     name: 'Corporate Credit Cards',
  //   },
  //   {
  //     id: 3,
  //     name: 'Paytm Wallets',
  //   },
  //   {
  //     id: 4,
  //     name: 'Cashfree Wallet',
  //   },
  // ];

  // const [animPayoutHero, setAnimPayoutHero] = useState(0);

  // const updateAnimPayoutHero = useCallback(() => {
  //   setAnimPayoutHero((state) => (state === 4 ? 0 : state + 1));
  // }, []);

  // useEffect(() => {
  //   const interval = setInterval(() => updateAnimPayoutHero(), 4000);

  //   return () => clearInterval(interval);
  // }, [updateAnimPayoutHero]);

  return (
    <Layout
      lightHeader
      {...props}
      signInLink="https://merchant.cashfree.com/merchants/login"
      createAccountLink="https://merchant.cashfree.com/merchants/signup?source-action=Payout%20Page&action=Sign%20Up&button-id=StartNow_Navbar">
      <SEO
        title="Payouts - Send instant Bulk Payouts 24×7 | Cashfree Payments"
        description="Automate bulk payments with Payouts. IMPS, NEFT, UPI supported. Use Payouts to disburse vendor payments, wages, refunds, loans, and more."
        url="https://www.cashfree.com/payouts/"
        keywords={[
          'payouts',
          'money transfer api provider',
          'imps API',
          'payout payment gateway',
          'imps api provider',
          'vendor payout',
          'bulk payouts',
          'instant payout',
          'cash payouts',
        ]}
      />
      <Helmet>
        <script type="application/ld+json">
          {`
{
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [{
    "@type": "Question",
    "name": "Do I need to be a registered business?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Yes, Payouts can be used only by registered businesses. If you are not yet registered as a business but plan to start soon, you can signup and explore the product in the test environment. We can also set up an account with limits on payout volume after understanding your business and future needs."
    }
  },{
    "@type": "Question",
    "name": "Does Cashfree Payout support payouts to all bank accounts?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Yes, you can make payouts to any active savings or current bank account in India. However, NRE and NRO bank accounts are not supported."
    }
  },{
    "@type": "Question",
    "name": "Can I transfer funds on Sundays and on bank holidays?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Yes, it is possible to make payouts even on Sundays and bank holidays."
    }
  },{
    "@type": "Question",
    "name": "How much time does it take to transfer funds?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Transfers to bank accounts happen instantly. Transfers for amounts in excess of ₹2,00,000 are restricted by banking hours and can take longer. Payouts to other modes like Paytm, AmazonPay wallet, cards, UPI ID happen instantly."
    }
  }]
}
`}
        </script>
      </Helmet>
      <section className="payouts-main">
        <div className="payouts-hero md:pt-[160px] pt-[111px] ">
          <div className="container position-relative">
            <div className="content text-white">
              <h1 className="small-title with-square text-white font-body">
                Payouts
              </h1>
              <div className="text-shl md:text-hero-large  font-heading">
                India's Largest <br /> Disbursal Platform
              </div>
              <div className="send-money-style mt-[16px] md:mt-[24px]">
                <div className="send-money-heading body-text font-semibold mb-2">
                  Send money from
                </div>
                {/* <video
                  autoPlay
                  muted
                  playsInline
                  loop
                  className="inset-video relative z-10 chrome-video md:ml-[-10px] ml-[-5px]"
                  width="1176"
                  height="884">
                  <source src="/img/payout/hero-anim.webm" type="video/webm" />
                </video> */}
                <TransparentVideo
                  containerClass="lg:left-[-6px]"
                  backgroundRef={ref1}
                  className="relative z-10"
                  poster="/img/payout/hero-anim-poster.png"
                  width="1220"
                  height="170"
                  mp4Src="/img/payout/hero-anim.mp4"
                  webmSrc="/img/payout/hero-anim.webm"
                />
              </div>
              {/* <div className="send-money-subheading flex items-center mt-[15px]">
                  <div className="py-3 relative left-section overflow-hidden w-[190px] h-[43px] text-center bg-white bg-opacity-10 text-2sm font-semibold rounded">
                    {leftSectionList.map(({ id, name }) => (
                      <span
                        key={id}
                        className={`${
                          animPayoutHero === id ? 'show-content' : ''
                        }`}>
                        {animPayoutHero === id ? name : ''}
                      </span>
                    ))}
                  </div>
                  <div className="flex items-center mx-[-4px]">
                    <div className="w-[8px] h-[8px] rounded-lg bg-cf-primary border border-white" />
                    <div className="w-9 border border-cf-stroke border-dashed" />
                    <div className="w-[8px] h-[8px] rounded-lg bg-cf-primary border border-white" />
                  </div>
                  <div className="py-3 right-section overflow-hidden w-[190px] text-center bg-white bg-opacity-10 text-2sm font-semibold rounded">
                    <span>Bank Account</span>
                  </div>
                </div> */}
              <div className="hero-content max-w-[511px] !mt-6 !md:mt-8">
                <h2 className="hero-content inline">Payouts</h2> helps you make{' '}
                <h2 className="hero-content inline">vendor payments</h2> and{' '}
                <h2 className="hero-content inline">customer payouts</h2>{' '}
                efficiently at scale.
                <h2 className="hero-content inline">Pay vendors</h2>, do{' '}
                <h2 className="hero-content inline">
                  instant customer refunds
                </h2>
                , disburse loans, etc instantly 24x7, even on a bank holiday.
              </div>

              <div className="buttons mt-[10px]">
                <a
                  href="https://merchant.cashfree.com/merchants/signup?source-action=Payout%20Page&action=Sign%20Up&button-id=Create_Account_Hero"
                  rel="noopener noreferrer"
                  className="button button-green w-full md:w-auto mb-4 md:mb-0">
                  Create Account <span className="chevron" />
                </a>
                <a
                  className="button button-outline no-shadow text-white border-cf-stroke w-full md:w-auto"
                  href="https://www.cashfree.com/contact-sales/?source-action=Payout%20Page&action=Contact%20Sales&button-id=ContactSales_Hero/">
                  Contact Sales <span className="chevron !mb-0" />
                </a>
              </div>
            </div>
            <div className="illustration lg:pl-[50px] xl:pl-[100px]">
              {/* <img
                src="https://cashfreelogo.cashfree.com/website/payouts/Payout-final-w-BG.gif"
                alt="payout-illustration"
                width={615}
                height={514}
              /> */}
              <TransparentVideo
                containerClass=" lg:left-[-10%] md:!p-14"
                backgroundRef={ref2}
                className="relative z-10"
                poster="/img/payout/poster.png"
                width="1300"
                height="900"
                mp4Src="/img/payout/payout-hero.mp4"
                webmSrc="/img/payout/payout-hero.webm"
              />
            </div>
            <div className="logos-mob mb-10 md:mb-6 block md:hidden logos relative overflow-hidden">
              <div className="">
                <LogoMarquee
                  data={[
                    {
                      id: 0,
                      src: 'img/payout/logo/meesho.svg',
                      alt: 'payout-logos',
                      // width: '107',
                      // height: '21',
                      // className: 'max-w-[74px]',
                    },
                    {
                      id: 1,
                      src: 'img/payout/logo/dream11.svg',
                      alt: 'dream11',
                      // width: '98',
                      // height: '23',
                      // className: 'max-w-[125px]',
                    },
                    {
                      id: 2,
                      src: 'img/payout/logo/cred.svg',
                      alt: 'cred',
                      // width: '74',
                      // height: '49',
                      // className: 'max-w-[59px]',
                    },
                    {
                      id: 3,
                      src: 'img/payout/logo/rapido.svg',
                      alt: 'rapido',
                      // width: '58',
                      // height: '22',
                      // className: 'max-w-[70px]',
                    },
                    {
                      id: 4,
                      src: 'img/payout/logo/khatabook.svg',
                      alt: 'khatabook',
                      // width: '129',
                      // height: '22',
                      // className: 'max-w-[125px]',
                    },
                    {
                      id: 5,
                      src: 'img/payout/logo/paytmfirst.svg',
                      alt: 'paytmfirst',
                      // width: '59',
                      // height: '22',
                      // className: 'max-w-[90px]',
                    },
                  ]}
                  imgUrl=""
                  duration="20s"
                />
                {}
                {/* <span>
                  <img
                    width={107}
                    className="logos-styles"
                    src="/img/payout/meesho.png"
                    alt="payout-logos"
                  />
                </span>
                <span>
                  <img
                    width={98.26}
                    className="logos-styles"
                    src="/img/payout/dream11.png"
                    alt="payout-logos"
                  />
                </span>
                <span>
                  <img
                    width={73.91}
                    className="logos-styles"
                    src="/img/payout/cred.png"
                    alt="payout-logos"
                  />
                </span>
                <span>
                  <img
                    width={58}
                    className="logos-styles"
                    src="/img/payout/rapido.png"
                    alt="payout-logos"
                  />
                </span>
                <span>
                  <img
                    width={129}
                    className="logos-styles"
                    src="/img/payout/khatabook.png"
                    alt="payout-logos"
                  />
                </span>
                <span>
                  <img
                    width={59}
                    className="logos-styles"
                    src="/img/payout/paytmfirst.png"
                    alt="payout-logos"
                  />
                </span> */}
              </div>
            </div>
            <div className="mt-[130px] mb-[64px] hidden md:block payouts-logos-style">
              <div className="flex justify-center items-center space-x-[70px]">
                <span>
                  <img
                    width={130}
                    className="logos-styles"
                    src="/img/payout/logo/meesho.svg"
                    alt="payout-logos"
                  />
                </span>
                <span>
                  <img
                    width={130}
                    className="logos-styles"
                    src="/img/payout/logo/dream11.svg"
                    alt="payout-logos"
                  />
                </span>
                <span>
                  <img
                    width={130}
                    className="logos-styles"
                    src="/img/payout/logo/cred1.svg"
                    alt="payout-logos"
                  />
                </span>
                <span>
                  <img
                    width={130}
                    className="logos-styles"
                    src="/img/payout/logo/rapido.svg"
                    alt="payout-logos"
                  />
                </span>
                <span>
                  <img
                    width={130}
                    className="logos-styles"
                    src="/img/payout/logo/khatabook.svg"
                    alt="payout-logos"
                  />
                </span>
                <span>
                  <img
                    width={130}
                    className="logos-styles"
                    src="/img/payout/logo/paytmfirst.svg"
                    alt="payout-logos"
                  />
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="py-[48px] md:py-[96px]">
          <div className=" container  ">
            <div className="flex justify-between md:max-w-[938px] mx-auto">
              <div className="flex flex-col items-center md:w-[280px]">
                <p className="text-cf-primary font-bold md:font-semibold leading-6 md:text-shs lg:text-[42px] md:leading-[48px] mb-[2px] md:mb-2">
                  2 Lakh Crore
                </p>
                <p className="w-full text-center text-tiny md:text-[15px] max-w-[90px] md:max-w-[140px] !leading-[18px] md:!leading-[24px]">
                  Payouts processed every year{' '}
                </p>
              </div>
              <div className="flex flex-col items-center md:w-[280px]">
                <p className="text-cf-primary font-bold md:font-semibold leading-6 md:text-shs lg:text-[42px] md:leading-[48px] mb-[2px] md:mb-2">
                  &gt; 50%
                </p>
                <p className="w-full text-center text-tiny md:text-[15px] max-w-[102px] md:max-w-[240px] !leading-[18px] md:!leading-[24px]">
                  Market share in bulk disbursals in India
                </p>
              </div>
              <div className="flex flex-col items-center md:w-[280px]">
                <p className="text-cf-primary font-bold md:font-semibold leading-6 md:text-shs lg:text-[42px] md:leading-[48px] mb-[2px] md:mb-2">
                  1000
                </p>
                <p className="w-full text-center text-tiny md:text-[15px] max-w-[90px] md:max-w-[140px] !leading-[18px] md:!leading-[24px]">
                  Payouts processed every second
                </p>
              </div>
            </div>
          </div>
        </div>
        <PayoutReimagined />

        <section className="p-section-2 bg-cf-dark text-white other-features-section upi-payment overflow-hidden pg-integration">
          <div className="container relative z-10">
            <div className="text-shs md:text-shl font-semibold mb-[32px] md:mb-16 font-heading max-w-[520px]">
              Choose from a range of destination payment modes
            </div>

            <div className="flex flex-wrap flex-row lg:mx-[-19px] lg:mt-[-19px]">
              {[
                {
                  id: 0,
                  title: 'Bank Account',
                  text:
                    'Send money to your customer’s bank account via IMPS, NEFT, or RTGS instantly. No cool off period for beneficiary addition!',
                  src: '/img/payout/banking.svg',
                },
                {
                  id: 1,
                  title: 'UPI or Wallets',
                  text:
                    'Initiate disbursals to customers UPI ID directly or add funds to their Paytm and Amazon Pay wallets.',
                  src: '/img/payout/UPI.svg',
                },
                {
                  id: 2,
                  title: 'Tokenised Cards',
                  text:
                    'Transfer money to any tokenised credit and debit cards instantly.',
                  src: '/img/payout/tokenisation-cards.svg',
                },
                {
                  id: 3,
                  title: 'Prepaid Cards',
                  text:
                    'Get your own branded prepaid cards with higher success rate compared to traditional payment modes.',
                  src: '/img/payout/prepaid cards.svg',
                },
              ].map(({ id, src, title, text }) => (
                <div
                  className="w-full lg:w-1/4 self-stretch pb-[16px] last:pb-0 lg:last:pb-[19px] lg:p-[19px] text-2.5sm md:text-md"
                  key={id}>
                  <div className=" bg-white bg-opacity-[0.07] min-h-52 min-h-[220px] md:min-h-[235px] md:h-full md:py-[24px] md:px-4 rounded border border-opacity-10 border-white flex flex-col py-[24px] px-[16px]">
                    <div className="w-[40px] h-[40px] md:w-[48px] md:h-[48px] flex justify-center items-center">
                      <img
                        src={src}
                        alt={title}
                        loading="lazy"
                        className="w-full  md:!max-w-full"
                      />
                    </div>
                    <div className="font-semibold pt-4 pb-2 md:pb-4 md:pt-[16px] leading-6 flex items-center text-[16px]">
                      {title}
                      {id === 3 ? (
                        <img
                          className="ml-2 mt-[-10px]"
                          src="/img/payout/comingsoon.svg"
                          alt="Coming soon"
                        />
                      ) : (
                        <></>
                      )}
                    </div>
                    <p className="text-cf-cold-purple md:text-2sm text-2.5sm">
                      {text}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        <div className="py-[48px] md:py-[128px] upi-payment-dropdown ">
          <div className="container">
            <h2 className="text-shs md:text-shl font-semibold max-w-[350px] sm:max-w-[710px]  mb-6 md:mb-8 font-heading">
              Make instant payouts in real-time!
            </h2>
            <Tabs
              selector={selector}
              data={checkoutOptionsData}
              dropDown
              timerAutoPlay
              onChange={() => tabChange(selector)}
              dropDownFontSize="text-[16px]"
            />
          </div>
        </div>

        <HighlyReliable />

        <div className="content-image-block py-[48px] md:py-[128px]">
          <section>
            <div className="container">
              <div className="text-4md md:text-shl mb-8 md:mb-[60px] font-heading">
                Comprehensive data visualisation
              </div>
              <div className="flex flex-col-reverse md:flex-row mb-12 md:mb-32">
                <div
                  // data-watch-visibility
                  className="column p-0">
                  <img
                    src="/img/payout/comprehensivedashboard.jpg"
                    alt="comprehensive-data"
                  />
                </div>
                <div className="column md:ml-[38px] p-0 pb-[28px] md:pb-0 flex flex-col justify-center">
                  <h2 className="font-semibold text-3md md:text-4md mb-3 md:mb-5 font-body">
                    Intuitive dashboards and insightful reports
                  </h2>
                  <p className="text-2sm md:text-[15px]">
                    Be it a birds-eye view of your funds from dashboard or an
                    in-depth drill-down of all your transfers from Reports,
                    Payouts ensures you always stay informed on your business
                    finances.
                  </p>
                </div>
              </div>
            </div>
          </section>

          <section className="risk-mitigation">
            <div className="container">
              <div className="row mobile-wrap">
                <div className="column mb-7 md:mb-0">
                  <div className="max-w-lg">
                    <h2 className="font-semibold text-3md md:text-4md mb-3 md:mb-5 font-body">
                      Industry-first bank downtime detector!
                    </h2>
                    <p className="text-2sm md:text-[15px] mb-8 md:mb-8">
                      Transparency is the name of the game. With our real-time
                      bank downtime tracker, we are the first company in the
                      industry that lets you keep track of any and every
                      unscheduled bank downtimes to help you plan your bulk
                      transfers better.
                    </p>

                    <a
                      target="_blank"
                      href="https://merchant.cashfree.com/merchants/signup?source-action=Payout%20Page&action=Sign%20Up&button-id=Create_Account_bankdowntime"
                      rel="noopener noreferrer"
                      className="button button-green w-full md:w-auto createAccountDiv">
                      Create Account
                      <span className="chevron" />
                    </a>
                  </div>
                </div>
                <div className="column">
                  <img
                    // src="https://cashfreelogo.cashfree.com/website/payouts/downtimedetector@2x.png"
                    src="/img/payout/downtimedetector.jpg"
                    alt="industry-first"
                  />
                </div>
              </div>
            </div>
          </section>
        </div>
        <PayoutTally />

        <div className="cashgram py-[48px] md:py-[96px] po-cashgram">
          <div className="container">
            <div className="mobile-wrap">
              <p className="small-title with-square">Payout Link</p>
              <h2 className="text-4md md:text-2lg">Cashgram</h2>
              <div className="md:mt-5 mt-3 heading-subtext">
                <p className="mb-8 text-2sm md:text-base max-w-[610px]">
                  Cashgram is payout links generation solution that helps
                  businesses pay vendors, partners, customers or others
                  instantly when only their mobile number or email address is
                  known.
                </p>
                <a
                  href="https://www.cashfree.com/cashgram/"
                  rel="noopener noreferrer"
                  className="button button-green w-full md:w-auto mb-[-12px] md:mb-0">
                  Explore Cashgram <span className="chevron" />
                </a>
              </div>
            </div>

            <div className="row row-no-margin align-flex-start tablet-wrap justify-start">
              {[
                {
                  id: 0,
                  cashgramcontent: (
                    <>
                      <div className="cashgram-asset-1 cashgram-asset">
                        <p className="asset-head">Create Cashgram link</p>
                        <div className="asset-body">
                          <div className="row row-no-margin justify-space-between cashgram-code">
                            <p
                              className={` ${
                                [0, 1, 2, 3, 4, 5].includes(cashgramAnim)
                                  ? 'active'
                                  : ''
                              }`}>
                              XMAS-ABCD-1234.
                            </p>
                            <p
                              className={` ${
                                [6, 7, 8, 9, 10, 11].includes(cashgramAnim)
                                  ? 'active'
                                  : ''
                              }`}>
                              TVCK-LFTY-6247.
                            </p>
                            <p
                              className={` ${
                                [12, 13, 14, 15, 16, 17].includes(cashgramAnim)
                                  ? 'active'
                                  : ''
                              }`}>
                              GVPG-XMAN-9143.
                            </p>
                            <p
                              className={` ${
                                [18, 19, 20, 21, 22, 23].includes(cashgramAnim)
                                  ? 'active'
                                  : ''
                              }`}>
                              AKSH-JUPQ-0778.
                            </p>

                            <Icon name="copy" fill="#6933d3" />
                          </div>

                          <p>
                            Copy this code and share with beneficiary via email,
                            SMS or WhatsApp.
                          </p>
                        </div>

                        <p className="asset-footer">Send link</p>
                      </div>
                      <div className="cashgram-text">
                        <p className="font-semibold mb-2 md:text-[16px]">
                          1. Send
                        </p>
                        <p className="md:w-80 text-[14px]">
                          Create a Cashgram link and notify via email, SMS or
                          WhatsApp.
                        </p>
                      </div>

                      <div
                        className={`line-anim ${
                          [0, 1, 7, 8, 13, 14, 19, 20].includes(cashgramAnim)
                            ? 'active'
                            : ''
                        }`}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="111px"
                          height="98px"
                          viewBox="0 0 111 98">
                          <path
                            stroke="#05C16E"
                            fill="none"
                            d="M8 5h43.992a2 2 0 012 2v84a2 2 0 002 2H106"
                          />
                          <circle
                            cx="5"
                            cy="5"
                            r="4"
                            fill="#05C16E"
                            stroke="#fff"
                          />
                          <circle
                            cx="106"
                            cy="93"
                            r="4"
                            fill="#05C16E"
                            stroke="#fff"
                          />
                        </svg>
                        <span>₹300</span>
                      </div>
                    </>
                  ),
                },
                {
                  id: 1,
                  cashgramcontent: (
                    <>
                      <div className="row row-no-margin mid-cashgram-img">
                        <div className="cashgram-asset-2 cashgram-asset py-8 px-6 !bg-white">
                          <p className="text-center text-vtiny mb-6 md:text-[13px] leading-[18px] font-semibold text-cf-hero">
                            Capture the details
                          </p>

                          <div className="row row-no-margin flex-wrap ">
                            <span
                              className={`single-pay-method text-vtiny md:text-[13px] leading-[18px] font-medium ${
                                [1].includes(cashgramAnim) ? 'active' : ''
                              }`}>
                              Bank Account
                            </span>
                            <span
                              className={`single-pay-method text-vtiny md:text-[13px] leading-[18px] font-medium ${
                                [8].includes(cashgramAnim) ? 'active' : ''
                              }`}>
                              Debit card
                            </span>
                            <span
                              className={`single-pay-method text-vtiny md:text-[13px] leading-[18px] font-medium ${
                                [14].includes(cashgramAnim) ? 'active' : ''
                              }`}>
                              UPI
                            </span>
                            <span
                              className={`single-pay-method text-vtiny md:text-[13px] leading-[18px] font-medium ${
                                [20].includes(cashgramAnim) ? 'active' : ''
                              }`}>
                              Wallets
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="cashgram-text">
                        <p className="font-semibold mb-2 md:text-[16px]">
                          2. Capture
                        </p>
                        <p className="text-2sm text-[14px]">
                          Beneficiary enters their bank account/ debit card/
                          UPI/ Paytm/Amazon Pay details.
                        </p>
                      </div>

                      <div
                        className={`line-anim line-anim-2 ${
                          [2, 3, 9, 10, 15, 16, 21, 22].includes(cashgramAnim)
                            ? 'active'
                            : ''
                        }`}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="111px"
                          height="98px"
                          viewBox="0 0 111 98">
                          <path
                            stroke="#05C16E"
                            fill="none"
                            d="M8 5h43.992a2 2 0 012 2v84a2 2 0 002 2H106"
                          />
                          <circle
                            cx="5"
                            cy="5"
                            r="4"
                            fill="#05C16E"
                            stroke="#fff"
                          />
                          <circle
                            cx="106"
                            cy="93"
                            r="4"
                            fill="#05C16E"
                            stroke="#fff"
                          />
                        </svg>
                        <span>₹300</span>
                      </div>
                    </>
                  ),
                },
                {
                  id: 2,
                  cashgramcontent: (
                    <>
                      <div
                        className={`row row-no-margin cashgram-asset cashgram-asset-3 ${
                          [4, 5, 11, 12, 17, 18, 23, 24].includes(cashgramAnim)
                            ? 'active'
                            : ''
                        }`}>
                        {[
                          {
                            id: 0,
                            message: (
                              <>
                                <div className="processing">
                                  <span className="icon-block">
                                    <img
                                      src="https://cashfreelogo.cashfree.com/website/landings/payouts/loader.svg"
                                      alt="loader"
                                      loading="lazy"
                                    />
                                  </span>
                                  <p>Waiting</p>
                                </div>
                              </>
                            ),
                          },
                          {
                            id: 1,
                            message: (
                              <>
                                <div className="successful">
                                  <span className="icon-block">
                                    <Icon
                                      style={{
                                        top: '15px',
                                        left: '12px',
                                      }}
                                      name="tick"
                                      width="18px"
                                      height="14px"
                                      fill="#fff"
                                    />
                                  </span>
                                  <p>Payout Successful</p>
                                </div>
                              </>
                            ),
                          },
                        ].map(({ id, message }) => (
                          <div key={id} className="process-message">
                            {message}
                          </div>
                        ))}
                      </div>

                      <div className="cashgram-text">
                        <p className="font-semibold mb-2 md:text-[16px]">
                          3. Redeem
                        </p>
                        <p className="text-2sm text-[14px]">
                          Beneficiary receives a payout in their preferred
                          account.
                        </p>
                      </div>
                    </>
                  ),
                },
              ].map(({ id, cashgramcontent }) => (
                <div key={id} className="cashgram-single">
                  {cashgramcontent}
                </div>
              ))}
            </div>

            <div
              className="row bg-cf-primary md:max-w-xs z-0"
              style={{
                position: 'relative',
                padding: '10px 16px',
                border: '1px solid rgba(255,255,255,0.1)',
                borderRadius: '4px',
                margin: '32px 0 0',
              }}>
              <div className="ribbon-purple ad before:z-[-1]">NEW</div>
              <div className="mt-8 flex">
                <img
                  src="https://cashfreelogo.cashfree.com/website/landings/payouts/whatsapp.svg"
                  alt="whatsapp"
                  width={32}
                  height={32}
                  loading="lazy"
                />
                <p className="text-white text-2sm ml-4">
                  Integration with WhatsApp for best customer experience
                </p>
              </div>
            </div>
          </div>
        </div>

        <section
          className="section-developers payouts-engineered py-[48px] md:py-[96px] cashgram-bg"
          data-spy-title="Integrations"
          data-spy-id="integrations">
          <div className="container position-relative">
            <div className="single-dev-block">
              <p
                className="small-title with-square white"
                style={{ color: '#fff', margin: '0 0 10px' }}>
                Engineered for you
              </p>
              <div className="white">
                <div className="hidden md:block hero-h2-custom text-shl font-heading">
                  Developer friendly, easy-to-integrate{' '}
                  <h2 className="inline">Payouts API</h2>
                </div>
                <div className="md:hidden hero-h2-custom text-shs font-heading">
                  Trusted by enterprises Loved by developers
                </div>
              </div>

              <ul className="check-list-round !max-w-[630px]">
                <li>
                  <span>
                    <Icon
                      name="tick"
                      width="9.21px"
                      height="6.29px"
                      fill="#fff"
                    />
                  </span>
                  <p className="text-2sm md:text-base">
                    Go live in minutes with easy to integrate REST APIs
                  </p>
                </li>
                <li>
                  <span>
                    <Icon
                      name="tick"
                      width="9.21px"
                      height="6.29px"
                      fill="#fff"
                    />
                  </span>
                  <p className="text-2sm md:text-base">
                    Use{' '}
                    <span className="bold text-white">sample integration</span>{' '}
                    kits to try out integrations using Test{' '}
                    <span className="bold text-white">API credentials</span>{' '}
                    before going live. API libraries available in multiple
                    languages
                  </p>
                </li>
                <li>
                  <span>
                    <Icon
                      name="tick"
                      width="9.21px"
                      height="6.29px"
                      fill="#fff"
                    />
                  </span>
                  <p className="text-2sm md:text-base">
                    With webhooks, get notified on transfer status - success,
                    pending, etc in real time
                  </p>
                </li>
              </ul>

              <a
                href="https://dev.cashfree.com/payouts"
                className="button button-green w-full md:w-auto">
                View API documentation <span className="chevron" />
              </a>

              <div className="terminal-asset mt-[28px] md:mt-[60px] rounded-[4px] overflow-hidden">
                <div className="terminal-tabs">
                  <span className="active max-w-[60px]">Node JS</span>
                  <span>Java</span>
                  <span>Python</span>
                  <span>PHP</span>
                  <span>cURL</span>
                </div>
                <div className="terminal-body">
                  <div className="terminal-content payout-terminal-content">
                    <p data-line-number="1">
                      <span style={{ color: '#a7a2e0' }}>
                        <span className="mr-[17px] md:mr-[30px]">1</span>const
                        cfSdk =
                      </span>
                      <span style={{ color: '#FBB016' }}>
                        require(&apos;cashfree-sdk&apos;)
                      </span>
                      ;
                    </p>
                    <p data-line-number="2">
                      <span style={{ color: '#a7a2e0' }}>
                        <span className="mr-[17px] md:mr-[30px]">2</span>const{' '}
                        <span style={{ color: '#6933d3' }}>
                          &#123;Transfers&#125;
                        </span>{' '}
                        ={' '}
                        <span style={{ color: '#FBB016' }}>cfSdk.Payouts;</span>
                      </span>
                    </p>
                    <p data-line-number="3">
                      <span style={{ color: '#a7a2e0' }}>
                        <span className="mr-[17px] md:mr-[30px]">3</span>const
                        response ={' '}
                        <span style={{ color: '#FBB016' }}>
                          await Transfers.RequestBatchTransfer
                        </span>
                      </span>
                      (
                    </p>
                    <p data-line-number="4">
                      <span style={{ color: '#a7a2e0' }}>
                        <span className="mr-[17px] md:mr-[30px]">4</span>&#123;
                        <span style={{ color: '#6933d3' }}>
                          batchTransferId :
                        </span>{' '}
                        <span style={{ color: '#FBB016' }}>
                          &apos;PAY_14APRIL_2021&apos;
                        </span>
                        ,
                      </span>
                    </p>
                    <p data-line-number="5">
                      <span style={{ color: '#a7a2e0' }}>
                        <span className="mr-[17px] md:mr-[30px]">5</span>
                        batchFormat :{' '}
                        <span style={{ color: '#FBB016' }}>
                          &apos;BANK_ACCOUNT&apos;
                        </span>{' '}
                      </span>
                      ,
                    </p>
                    <p data-line-number="6">
                      <span style={{ color: '#a7a2e0' }}>
                        <span className="mr-[17px] md:mr-[30px]">6</span>
                        deleteBene : <span style={{ color: '#FBB016' }}>1</span>
                        ,{' '}
                      </span>
                    </p>
                    <p data-line-number="7">
                      <span style={{ color: '#a7a2e0' }}>
                        <span className="mr-[17px] md:mr-[30px]">~</span>batch :
                      </span>{' '}
                      [
                    </p>
                    <p data-line-number="8">
                      <span style={{ color: '#a7a2e0' }}>
                        <span className="mr-[17px] md:mr-[30px]">~</span>
                      </span>
                      &#123;{' '}
                      <span style={{ color: '#6933d3' }}>transferId</span> :{' '}
                      <span style={{ color: '#fff' }}>
                        &apos;VENDOR_512623_PAY_14APRIL&apos;
                      </span>
                      ,{' '}
                    </p>
                    <p data-line-number="9">
                      <span style={{ color: '#a7a2e0' }}>
                        <span className="mr-[17px] md:mr-[30px]">~</span>
                      </span>
                      amount:{' '}
                      <span style={{ color: '#05c16e' }}>
                        &apos;1023.4&apos;,
                      </span>
                    </p>
                    <p data-line-number="10">
                      <span style={{ color: '#a7a2e0' }}>
                        <span className="mr-[17px] md:mr-[30px]">~</span>
                      </span>
                      phone :{' '}
                      <span style={{ color: '#05c16e' }}>
                        &apos;9999999999&apos;,{' '}
                      </span>
                    </p>
                    <p data-line-number="11">
                      <span style={{ color: '#a7a2e0' }}>
                        <span className="mr-[17px] md:mr-[30px]">~</span>
                      </span>
                      bankAccount :{' '}
                      <span style={{ color: '#05c16e' }}>
                        &apos;1007766300076281&apos; ,{' '}
                      </span>
                    </p>
                    <p data-line-number="12">
                      <span style={{ color: '#a7a2e0' }}>
                        <span className="mr-[17px] md:mr-[30px]">~</span>
                      </span>
                      ifsc :{' '}
                      <span style={{ color: '#05c16e' }}>
                        &apos;HDFC0001007&apos;,
                      </span>
                    </p>
                    <p data-line-number="13">
                      <span style={{ color: '#a7a2e0' }}>
                        <span className="mr-[17px] md:mr-[30px]">~</span>
                      </span>
                      email :{' '}
                      <span style={{ color: '#05c16e' }}>
                        &apos;johndoe@cashfree.com&apos;,
                      </span>
                    </p>
                    <p data-line-number="14">
                      <span style={{ color: '#a7a2e0' }}>
                        <span className="mr-[17px] md:mr-[30px]">~</span>
                      </span>
                      name:{' '}
                      <span style={{ color: '#05c16e' }}>&apos;John&apos;</span>{' '}
                      &#125;
                    </p>
                    <p data-line-number="15">
                      <span style={{ color: '#a7a2e0' }}>
                        <span className="mr-[17px] md:mr-[30px]">~</span>
                      </span>
                      ]
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="single-dev-block">
              <div className="flex flex-col-reverse md:flex-row items-center md:items-center">
                <img
                  className="w-full h-full mt-2 md:mt-0 md:w-auto md:h-auto"
                  src="/img/payout/upload-batch-transfer.svg"
                  alt=""
                  width={254}
                  height={212}
                  loading="lazy"
                />

                <div className="no-code-solution">
                  <h4 className="body-font white !mb-5">No code solutions</h4>
                  <ul className="check-list-round m-0 md:!max-w-[500px] !max-w-[335px]">
                    <li>
                      <span className="mt-1 flex-shrink-0">
                        <Icon
                          name="tick"
                          width="9.21px"
                          height="6.29px"
                          fill="#fff"
                        />
                      </span>
                      <div className="md:text-[15px] text-[14px] text-cf-cold-purple m-0">
                        Make <h2 className="inline font-body">single payout</h2>{' '}
                        using Quick Transfer on Dashboard by entering
                        beneficiary&apos;s account details.
                      </div>
                    </li>
                    <li>
                      <span className="flex-shrink-0">
                        <Icon
                          name="tick"
                          width="9.21px"
                          height="6.29px"
                          fill="#fff"
                        />
                      </span>
                      <div className="md:text-[15px] text-[14px] text-cf-cold-purple m-0">
                        Do <h2 className="inline font-body">bulk payouts</h2>{' '}
                        easily and quickly from the Dashboard by uploading
                        excel. Just enter beneficiary&apos;s account details and
                        make payouts in seconds.
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className=" usecase-main payout-usecases py-[48px] md:py-[128px] overflow-hidden">
          <div className="container position-relative">
            <p className="small-title with-square">
              Use cases for various industries
            </p>
            <div className="hero-h2-custom text-shs md:text-shl font-heading">
              <h2 className="inline">Payouts</h2> for innovative businesses
            </div>
            <div className="max-w-[650px] md:mt-5 mt-3 text-2sm text-cf-hero">
              <h2 className="inline font-body">Send money to vendors</h2>, do
              customer refunds or automate loan disbursals. Get creative and
              solve your business payouts challenges with our powerful{' '}
              <h2 className="inline font-body">Payouts APIs</h2>.
            </div>
            <div className="flex payment-link-business-slider md:mt-10 mt-8">
              <div className="w-full ">
                <BusinessSlider
                  data={businessCards}
                  settings={{
                    dots: true,
                    arrows: false,
                    infinite: false,
                    speed: 500,
                    slidesToShow: 3,
                    responsive: [
                      {
                        breakpoint: 768,
                        settings: {
                          slidesToShow: 1.3,
                          infinite: false,
                          arrows: true,
                          dots: true,
                        },
                      },
                    ],
                  }}
                  OpenVideoModalToggle={OpenVideoModalToggle}
                />
              </div>
            </div>
          </div>
        </div>

        <div
          data-spy-title="Pricing"
          data-spy-id="pricing"
          className="gateway position-relative no-pseudo payout-gateway md:!py-[96px] !py-12">
          <div className="small-container">
            <div className="center flex flex-col items-center">
              <p className="small-title with-square center-title">Pricing</p>
              <h2 className="md:mb-[60px] mb-8 text-center text-shs md:text-shl">
                Simple Pricing, No Hidden Charges
              </h2>
            </div>
          </div>
          <div className="small-container position-relative">
            <div className="row row-no-margin align-flex-start flex-wrap">
              <div className="left">
                <div className="md:flex justify-between bg-white p-4 md:py-6 md:px-[38px] mb-2 items-center">
                  <div>
                    <p className="text-[13px] text-cf-primary font-semibold">
                      STANDARD
                    </p>
                    <p className="text-md font-semibold">
                      Pay as you go - For Startups
                    </p>
                  </div>
                  <a
                    href="https://merchant.cashfree.com/merchants/signup?source-action=Payout%20Page&action=Sign%20Up&button-id=StartNow_Pricing"
                    rel="noopener noreferrer"
                    className="w-full mt-8 md:mt-0 md:w-auto button button-green ">
                    Create Account <span className="chevron" />
                  </a>
                </div>
                <div className="accordion-group">
                  {[
                    {
                      id: 0,
                      title: 'Transaction mode',
                      body: (
                        <div className="px-[5px] sm:pl-[26px]">
                          <table className="pricing-table table-auto">
                            <thead>
                              <tr>
                                <th>IMPS</th>
                                <th>NEFT</th>
                                <th>UPI</th>
                                <th>&nbsp;</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td className="text-cf-primary !text-tiny leading-4 md:!text-2sm">
                                  ₹ 2.50
                                </td>
                                <td className="text-cf-primary !text-tiny leading-4 md:!text-2sm">
                                  ₹ 1.75
                                </td>
                                <td className="text-cf-primary !text-tiny leading-4 md:!text-2sm">
                                  ₹ 3.00
                                </td>
                                <td className="!text-tiny md:!text-2sm w-[10%]">
                                  up to ₹1,000
                                </td>
                              </tr>
                              <tr>
                                <td className="text-cf-primary !text-tiny leading-4 md:!text-2sm">
                                  ₹ 5.00
                                </td>
                                <td className="text-cf-primary !text-tiny leading-4 md:!text-2sm">
                                  ₹ 1.75
                                </td>
                                <td className="text-cf-primary !text-tiny leading-4 md:!text-2sm">
                                  ₹ 6.00
                                </td>
                                <td className="!text-tiny md:!text-2sm w-[10%]">
                                  ₹1,001 to ₹10,000
                                </td>
                              </tr>
                              <tr>
                                <td className="text-cf-primary !text-tiny leading-4 md:!text-2sm">
                                  ₹ 10.00
                                </td>
                                <td className="text-cf-primary !text-tiny leading-4 md:!text-2sm">
                                  ₹ 1.75
                                </td>
                                <td className="text-cf-primary !text-tiny leading-4 md:!text-2sm">
                                  ₹ 10.00
                                </td>
                                <td className="!text-tiny md:!text-2sm w-[10%]">
                                  Above ₹10,000
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      ),
                    },

                    {
                      id: 1,
                      title: 'Cards',
                      percent: '₹ 10.00',
                      body: (
                        <>
                          <div className="styled-payment-gateway">
                            <img
                              src="/img/payout/visa-b.svg"
                              alt="visa"
                              loading="lazy"
                            />
                            <span>Visa</span>
                          </div>
                          <div className="styled-payment-gateway">
                            <img
                              src="/img/payout/mastercard-b.svg"
                              alt="mastercard"
                              loading="lazy"
                            />
                            <span>Mastercard</span>
                          </div>
                        </>
                      ),
                    },
                    {
                      id: 2,
                      title: 'Wallets',
                      body: (
                        <>
                          <div className="row row-no-margin justify-flex-start align-flex-start payout-wallets-main !flex !flex-row">
                            <div className="payout-wallet pl-[11px] pb-[11px]">
                              <p className="green !text-[12px] md:!text-[14px] my-[10px] md:my-0">
                                Free<sup>*</sup>
                              </p>
                              <div className="text-center inline-block">
                                <div className="text-center inline-flex items-center justify-center w-[48px] h-[48px] border border-cf-stroke rounded-full mx-auto mb-2 mt-4">
                                  <img
                                    width="48"
                                    src="/img/icons/amazon-black.png"
                                    alt="{heading}"
                                  />
                                </div>
                                <h5 className="font-body text-tiny md:text-2sm font-normal w-max">
                                  Amazon Pay
                                </h5>
                              </div>
                            </div>
                            <div className="payout-wallet pb-[11px]">
                              <p className="!text-[12px] md:!text-[14px]">
                                As applicable on your Paytm business account
                              </p>
                              <div className="text-center inline-block pl-7">
                                <div className="text-center inline-flex items-center justify-center w-[48px] h-[48px] border border-cf-stroke rounded-full mx-auto mb-2 mt-4">
                                  <img
                                    width="32"
                                    src="/img/icons/paytm.svg"
                                    alt="{heading}"
                                  />
                                </div>
                                <h5 className="font-body text-tiny md:text-2sm font-normal w-max">
                                  Paytm
                                </h5>
                              </div>
                            </div>
                          </div>
                        </>
                      ),
                    },
                  ].map(({ id, title, percent, body }) => (
                    <div
                      key={id}
                      className={`accordion${
                        gatwayActive === id ? ' is-active' : ''
                      }`}>
                      <button
                        type="button"
                        className="button-unstyled accordion-head"
                        onClick={toggleGatewayAccordion(id)}
                        style={{ cursor: 'default' }}>
                        <span className="bold !text-md">{title}</span>
                        <span className="inline-flex align-center">
                          <span className="!text-cf-primary font-semibold text-tiny leading-4 sm:text-2sm">
                            {percent}
                          </span>
                          <Icon
                            name="chevron"
                            width="11px"
                            verticalAlign="middle"
                            rotate={gatwayActive === id ? '180deg' : '0deg'}
                          />
                        </span>
                      </button>
                      <div className="accordion-body">{body}</div>
                    </div>
                  ))}
                </div>
                <p
                  style={{
                    fontSize: '13px',
                    fontStyle: 'italic',
                    margin: '15px 0 0',
                  }}>
                  GST Applicable
                </p>
                <p
                  style={{
                    fontSize: '13px',
                    fontStyle: 'italic',
                  }}>
                  * Limited period offer only. Applicable till 31st May 2023.
                  T&C Apply
                </p>
              </div>
              <div className=" w-full xl:max-w-[400px] mt-4 md:mt-0">
                {/* <EnterprisePricing /> */}
                <div className="enterprise-pricing-card w-full text-left lg:min-w-[400px] bg-cf-hero border-t-[5px] border-cf-primary rounded-[4px] mt-[32px] md:mt-0">
                  <div className="card-content">
                    <small className="enterprise-heading">ENTERPRISES</small>
                    <p className="bold text-3md mt-[10px] mb-[28px] max-w-[245px] ">
                      Optimized payments infrastructure that helps you scale.
                    </p>
                    <ul className="flex flex-col text-2md">
                      {[
                        {
                          id: 0,
                          item: 'Dedicated account manager',
                        },
                        {
                          id: 1,
                          item: 'Volume discounts',
                        },
                        {
                          id: 2,
                          item: 'Migration support',
                        },
                        {
                          id: 3,
                          item: 'Dedicated support',
                        },
                      ].map(({ id, item }) => (
                        <li key={id} className="flex mb-3 last:mb-0">
                          <span className="flex items-center justify-center mt-0.5 w-5 h-5 rounded-full flex-none bg-white bg-opacity-25">
                            <Icon
                              name="tick"
                              width="9.2px"
                              height="7px"
                              fill="#fff"
                            />
                          </span>
                          <p className="!text-cf-white max-w-[300px] ml-2 text-md">
                            {item}
                          </p>
                        </li>
                      ))}
                    </ul>
                    <p className="text-cf-cold-purple my-[28px] text-tiny">
                      Get in touch with our sales team to explore the right
                      Cashfree Payments product(s) for your payment needs and
                      get custom&nbsp;pricing.
                    </p>
                  </div>
                  <a
                    href="https://www.cashfree.com/contact-sales/?source-action=Payout%20Page&action=Contact%20Sales&button-id=ContactSales_Pricing/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="button button-outline w-full !shadow-none">
                    Contact Sales
                    <span className="chevron" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container general-faqs">
          <FAQ data={faqData} open="0" />
        </div>

        <div className="other-products section-padding md:!py-[96px] !py-[48px]">
          <div className="container">
            <h2 className="mb-8 sm:mb-[60px] text-4md sm:text-shl ">
              Explore other products
            </h2>
            <div className="row flex-wrap mobile-wrap">
              {exploreOtherProducts.map(
                ({ id, title, body, url, iconname }) => (
                  <div
                    className="other-product-col  w-full md:w-1/2 text-cf-hero"
                    key={id}>
                    <div className="flex md:h-[170px] bg-white mb-[16px] md:mb-[30px] rounded-[4px] px-5 py-6 md:p-8 ">
                      <img
                        src={iconname}
                        alt={title}
                        width={44}
                        height={44}
                        loading="lazy"
                        className="w-[40px] h-[40px] sm:w-[44px] sm:h-[44px] mr-[10px] sm:mr-6"
                      />
                      <div className="other-products-text flex flex-col">
                        <p className="font-semibold  leading-6">{title}</p>
                        <p className="my-2 text-2sm  flex-grow">{body}</p>
                        <a
                          href={url}
                          className="link text-cf-green hover:text-cf-green text-2sm mt-[24px] md:mt-0">
                          Learn more
                          <svg
                            className="ml-2.5 w-2 h-2"
                            width="6"
                            height="6"
                            viewBox="0 0 6 11"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M1 1L5 5.5L1 10"
                              stroke="#05C16E"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </a>
                      </div>
                    </div>
                  </div>
                ),
              )}
            </div>
          </div>
        </div>

        <GetStarted
          startNowLink="https://merchant.cashfree.com/merchants/signup?source-action=Payout%20Page&action=Sign%20Up&button-id=StartNow_Footer"
          getInTouchLink="https://www.cashfree.com/contact-sales/?source-action=Payout%20Page&action=Contact%20Sales&button-id=ContactSales_Footer/"
          customClass="!max-w-[630px]"
        />
      </section>
      <div
        className={`row row-no-margin modal-video-main ${
          OpenVideoModal ? 'show-modal' : ''
        }`}>
        <div className="modal-video">
          <button
            type="button"
            onClick={OpenVideoModalToggle}
            className="close-video-modal  flex justify-center items-center">
            <Icon name="close" />
          </button>
          <iframe
            src={
              OpenVideoModal ? 'https://www.youtube.com/embed/SA4H5UAhzCQ' : ''
            }
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
      </div>
    </Layout>
  );
};

export default PayoutsPage;
